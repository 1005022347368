import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Slider,
  IconButton,
  useMediaQuery,
  Stack,
  Box,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import drakePhoto from '../images/drake.jpeg';
import ksiPhoto from '../images/ksi-zesty.webp';
import lilNas from '../images/lilnasx.jpeg';
import mosey from '../images/lil mosey.jpeg';

const songs = [
  { title: 'Family Matters', artist: 'Drake', duration: '2:53', file: './songs/drake.mp3', img: drakePhoto},
  { title: 'Holiday', artist: 'KSI', duration: '2:51', file: './songs/ksi.mp3', img: ksiPhoto},
  { title: 'Montero', artist: 'Lil Nas X', duration: '2:30', file: './songs/Montero.mp3', img: lilNas},
  { title: 'Noticed', artist: 'Lil Mosey', duration: '2:16', file: './songs/Noticed.mp3', img: mosey},
];

const Music = () => {
    const mobile = useMediaQuery('(max-width:815px)');
  const [selectedSong, setSelectedSong] = useState(songs[0]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(100);
  const audioRef = useRef(new Audio());

  const handleSongClick = (song) => {
    setSelectedSong(song);
    audioRef.current.src = song.file;
    setIsPlaying(true);
    audioRef.current.play();
  };

  const handlePlayPauseClick = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
        if (!audioRef.current.src) {
            audioRef.current.src = songs[0].file;
        }
        try {
            audioRef.current.play();
        } catch (err) {
            setSelectedSong(songs[0]);
            audioRef.current.src = songs[0].file;
            setIsPlaying(true);
            audioRef.current.play();
        }
    }
    setIsPlaying(!isPlaying);
  };

  const handleProgressChange = (event, newValue) => {
    if (audioRef.current) {
        const newTime = (audioRef.current.duration * newValue) / 100;
        audioRef.current.currentTime = newTime;
        setProgress(newValue);
    }
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    audioRef.current.volume = newValue / 100;
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
        const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
        setProgress(progress);
    }
    
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  return (
    <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Stack direction={mobile ? "column" : "row"} spacing={2} alignItems="start" px={2}>
          <List sx={{ width: mobile ? '100%' : '70vw', height: mobile ? '40vh' : '100%', overflowY: 'auto' }}>
            {songs.map((song, index) => (
              <ListItem
                key={index}
                button
                selected={selectedSong?.title === song.title}
                onClick={() => handleSongClick(song)}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#f22e1c',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'darkred',
                    },
                  },
                  borderRadius: 2,
                }}
              >
                <ListItemText primary={song.title} secondary={song.artist} />
              </ListItem>
            ))}
          </List>
          <Box width={mobile ? '27vh' : "70%"}>
            <img
              src={selectedSong?.img}
              alt={selectedSong?.artist}
              style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
            />
          </Box>
        </Stack>
      </Box>
      <Box
        sx={{
          m: 2,
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          paddingRight: '16px', // Add padding to ensure the slider stays within the bounds
          boxSizing: 'border-box', // Ensure padding is included in the total width
        }}
      >
        <IconButton onClick={handlePlayPauseClick}>
          {isPlaying ? <PauseIcon sx={{fontSize: mobile ? 22 : 26}}/> : <PlayArrowIcon sx={{fontSize: mobile ? 22 : 26}} disabled={!selectedSong}/>}
        </IconButton>
        <Slider
            size={mobile ? "small" : "medium"}
          value={progress}
          onChange={handleProgressChange}
          sx={{
            mx: 2,
            flexGrow: 1,
            color: 'red',
            '& .MuiSlider-thumb': {
              backgroundColor: 'red',
            },
            '& .MuiSlider-track': {
              backgroundColor: 'red',
            },
            '& .MuiSlider-rail': {
              backgroundColor: 'lightgrey',
            },
          }}
        />
        <Box display="flex" alignItems="center" sx={{ flexShrink: 0 }} ml={mobile ? 0.5 : 2}>
          <VolumeUpIcon sx={{fontSize: mobile ? 22 : 26}}/>
          <Slider
            value={volume}
            size={mobile ? "small" : "medium"}
            onChange={handleVolumeChange}
            sx={{
              width: '8vw',
              ml: 1,
              mr: mobile ? '5vw' : '2vw',
              color: 'red',
              '& .MuiSlider-thumb': {
                backgroundColor: 'red',
              },
              '& .MuiSlider-track': {
                backgroundColor: 'red',
              },
              '& .MuiSlider-rail': {
                backgroundColor: 'lightgrey',
              },
            }}
          />
        </Box>
      </Box>
      <audio ref={audioRef} />
    </Box>
  );
};

export default Music;
