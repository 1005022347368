import React from 'react';
import { Box, Stack, Avatar, Typography, Divider, useMediaQuery } from '@mui/material';

function SideStack({name, image, message, main=false}) {
    const mobile = useMediaQuery('(max-width:915px)');
  return (
    <Stack direction="row" p={1} pr={0} py={0} spacing={1} bgcolor={main ? "#0166f7" : mobile ? "#fff" : "#c5c5c5"} width={mobile ? '100%' : "23vw"} height="13vh" 
    sx={{cursor: "pointer", ":hover": {bgcolor: "darkgray", borderRadius: 2}, borderRadius: main ? 2 : 0}} >
        <Box pt='2.5vh'>
            <Avatar src={image} alt={name} sx={{height:"8vh", width:"8vh"}}/>
        </Box>
        <Stack direction="column" alignItems="start" spacing={0} width="100%">
            <Box flex={1} />
            <Typography color={main ? "#fff" : '#696969'} fontSize="2.5vh" fontWeight="bold">
                {name}
            </Typography>
            <Typography color={main ? "#fff" : '#696969'} fontSize="2vh">
                {message}
            </Typography>
            <Box flex={1} />
            { !main && <Divider flexItem sx={{my: 'none', py: 'none'}}/>}
        </Stack>
    </Stack>
  );
}

export default SideStack;
