import React from 'react';
import { Box, Typography, Stack, Avatar, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

const ChatBubble = styled(Box)(({ theme, sender }) => ({
  backgroundColor: sender ? theme.palette.primary.main : theme.palette.neutral.light,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  borderRadius: sender ? '16px 16px 0 16px' : '16px 16px 16px 0',
  display: 'inline-block',
  maxWidth: '85%',
  wordWrap: 'break-word',
  marginRight: sender ? '10px' : 0,
  marginLeft: sender ? 0 : '10px',
}));

const ChatBubbleComponent = ({ text, sender }) => {
  const mobile = useMediaQuery('(max-width:915px)');
  return (
    <Stack width="100%" direction="row" alignItems="center" pb={2}>
        {sender && <Box flex={1} />}
        <ChatBubble sender={sender}>
            <Typography fontSize={mobile ? 13 : '1.2vw'} color={sender ? '#fff' : '#696969'}>{text}</Typography>
        </ChatBubble>
    </Stack>
    
  );
};

export default ChatBubbleComponent;
