import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Box, Tooltip, Snackbar, Stack, Typography, Avatar, IconButton, useMediaQuery, Card, Divider, Button, TextField, Modal } from '@mui/material';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import PhotoLibraryRoundedIcon from '@mui/icons-material/PhotoLibraryRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import dex from './images/dex.webp';
import telegram from './images/telegram.webp';
import x from './images/x.webp';
import SideStack from './common/SideStack';
import NoteStack from './common/NoteStack';
import ChatBubbleComponent from './common/ChatBubble';
import ksiImage from './images/ksi.webp';
import jpImage from './images/jp.jpg';
import mmImage from './images/mm.jpg';
import ddImage from './images/dd.jpeg';
import ksiResponse from './images/ksi_response.mp3';
import ksiResponse2 from './images/ksi_response2.mp3';
import AudioPlayer from './common/AudioPlayer';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import Music from './common/Music';

import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';

import dd1 from './images/dd_photo1.jpeg';
import dd2 from './images/dd_photo2.jpeg';
import dd3 from './images/dd_3.jpeg';
import dd4 from './images/dd_4.jpeg';
import dd5 from './images/dd5.jpeg';

import pride1 from './images/pride.webp';
import pride2 from './images/PRIDE2.png';
import pride3 from './images/PRIDE3.png';
import pride4 from './images/PRIDE_4.png';
import pride5 from './images/PRIDE_5.png';
import pride7 from './images/PRIDE7.png';
import wwe from './images/loganpolge.png';
import paulxmike from './images/polge.png';
import paulescam from './images/paulescame.png';
import logane from './images/logane.png';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4a4a4a',
        borderRadius: '25px',
      },
      '&:hover fieldset': {
        borderColor: '#4a4a4a',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4f4f4f',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    },
}));

const kMessages = [
    {
        text: 'Did you try the newest Pride flavor yet?',
        sender: true,
    },
    {
        text: <AudioPlayer src={ksiResponse} title="Audio Message"/>,
        sender: false,
    },
    {
        text: "Just making sure you weren't retarded lol",
        sender: true,
    },
    {
        text: "👍",
        sender: false,
    },
    {
        text: <AudioPlayer src={ksiResponse2} title="Audio Message"/>,
        sender: false,
    },
    {
        text: "❤️",
        sender: true,
    }
]

const lMessages = [
    {
        text: 'Hey, do I actually have refund the Crypto Zoo investors?',
        sender: true,
    },
    {
        text: 'Yes.',
        sender: false,
    },
    {
        text: ':(',
        sender: true,
    },
    {
        text: "I'll just tell people that I will, but never actually do it ;)",
        sender: true,
    },
    {
        text: "I don't think that's a good idea",
        sender: false,
    },
    {
        text: "trust",
        sender: true,
    },
    {
        text: "I'm a lawyer, not a magician",
        sender: false,
    },
    {
        text: "Also you got to do something about the PFOs in your drink",
        sender: false,
    },
    {
        text: "People are eventually gonna see through the BS",
        sender: false,
    },

]

const bjMessages = [
    {
        text: 'I slept with you wife btw',
        sender: false,
    },
    {
        text: 'Make us even for the Alissa thing lmao',
        sender: false,
    },
    {
        text: 'idc lol',
        sender: true,
    },
    {
        text: "Yk I'm gay right?",
        sender: true,
    },
    {
        text: "how could i forget lol",
        sender: false,
    },
    {
        text: "have fun with ur bf",
        sender: false,
    },
    {
        text: "happy pride",
        sender: false,
    },

]

const mmMessages = [
    {
        text: 'hey cutie',
        sender: false,
    },
    {
        text: 'can we finally go public with our relationship?',
        sender: false,
    },
    {
        text: "i think now's the right time",
        sender: false,
    },
    {
        text: "hello???",
        sender: false,
    },
    {
        text: "answer me babe",
        sender: false,
    },
    {
        text: "why aren't you answering!?",
        sender: false,
    },
    {
        text: "don't tell me you're cheating on me with ksi again",
        sender: false,
    }
]


const images = [
    {
        img: logane,
        title: 'Logan.png',
    },
    {
        img: paulxmike,
        title: 'LoganxMike.png',
    },
    {
        img: paulescam,
        title: 'Forest.png',
    },
    {
        img: wwe,
        title: 'WWE.png',
    },
    {
        img: pride1,
        title: 'Pride.png',
    },
    {
        img: pride2,
        title: 'Pride.png',
    },
    {
        img: pride3,
        title: 'Pride.png',
    },
    {
        img: pride4,
        title: 'Pride.png',
    },
    {
        img: pride5,
        title: 'Pride.png',
    },
    {
        img: pride7,
        title: 'Pride.png',
    },
    
];

const tokenAddressNote = {
    title: 'Token Address',
    content: 'AZ5je6uZDWsKvjq9wyRGTMXKRTNPXhXQrNA4LqhNpump',
}

const tokenomicsNote = {
    title: 'Tokenomics',
    content: '95% Fair launch, 4% Team, 1% Marketing'
}

const roadmapNote = {
    title: 'Roadmap',
    content: 'Phase 1 - Launch on pump.fun, Phase 2 - Hit Raydium, Phase 3 - Convince Logan Paul and KSI to shill, Phase 4 - Moon',
}

function Home() {
    const mobile = useMediaQuery('(max-width:915px)');
    const chatContainerRef = useRef(null);
    const [musicOpen, setMusicOpen] = useState(false);
    const [photosOpen, setPhotosOpen] = useState(false);
    const [browserOpen, setBrowserOpen] = useState(false);
    const [notesOpen, setNotesOpen] = useState(false);
    const [messagesOpen, setMessagesOpen] = useState(true);
    const [curMessages, setCurMessages] = useState(kMessages);
    const [curNote, setCurNote] = useState(tokenAddressNote);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [messageClicked, setMessageClicked] = useState(false);
    const [noteClicked, setNoteClicked] = useState(false);
    const [textValue, setTextValue] = useState('');
    const [ksiMessages, setKsiMessages] = useState(kMessages);
    const [lawyerMessages, setLawyerMessages] = useState(lMessages);
    const [mikeMajlakMessages, setMikeMajlakMessages] = useState(mmMessages);
    const [brotherJakeyMessages, setBrotherJakeyMessages] = useState(bjMessages);
    const dexLink = 'https://dexscreener.com/solana/9ehN55DRm5AYoNp7HjBcTypqFSHm3Gbjg3pvBaq3EYq4';

const ddMessages = [
    {
        text: <img src={dd1} alt="dillon dannis 1" style={{width: mobile ? '60vw' : '25vw', height: 'auto'}}/>,
        sender: false,
    },
    {
        text: <img src={dd2} alt="dillon dannis 2" style={{width: mobile ? '60vw' : '25vw', height: 'auto'}}/>,
        sender: false,
    },
    {
        text: <img src={dd3} alt="dillon dannis 1" style={{width: mobile ? '60vw' : '25vw', height: 'auto'}}/>,
        sender: false,
    },
    {
        text: <img src={dd4} alt="dillon dannis 2" style={{width: mobile ? '60vw' : '25vw', height: 'auto'}}/>,
        sender: false,
    },
    {
        text: <img src={dd5} alt="dillon dannis 1" style={{width: mobile ? '60vw' : '25vw', height: 'auto'}}/>,
        sender: false,
    },
]

    const [dillonDannisMessages, setDillonDannisMessages] = useState(ddMessages);


    const handleMessagesClick = () => {
        setMessagesOpen(true);
        setMusicOpen(false);
        setPhotosOpen(false);
        setNotesOpen(false);
        setBrowserOpen(false);
    }

    const handleMusicClick = () => {
        setMessagesOpen(false);
        setMusicOpen(true);
        setPhotosOpen(false);
        setNotesOpen(false);
        setBrowserOpen(false);
    }

    const handlePhotosClick = () => {
        setMessagesOpen(false);
        setMusicOpen(false);
        setPhotosOpen(true);
        setNotesOpen(false);
        setBrowserOpen(false);
    }

    const handleBrowserClick = () => {
        setMessagesOpen(false);
        setMusicOpen(false);
        setPhotosOpen(false);
        setNotesOpen(false);
        setBrowserOpen(true);
    }

    const handleNotesClick = () => {
        setMessagesOpen(false);
        setMusicOpen(false);
        setPhotosOpen(false);
        setNotesOpen(true);
        setBrowserOpen(false);
    }

    const handleSendMessage = (event) => {
        if (textValue.trim() && event.key === 'Enter') {
            // Instantly show the user's message
            const newMessages = [...curMessages, { text: textValue, sender: true }];
            if (messageClicked === 'KSI') {
                setKsiMessages(newMessages);
            } else if (messageClicked === 'Lawyer') {
                setLawyerMessages(newMessages);
            } else if (messageClicked === 'Brother Jakey') {
                setBrotherJakeyMessages(newMessages);
            } else if (messageClicked === 'Mike Majlak') {
                setMikeMajlakMessages(newMessages);
            } else if (messageClicked === 'Dillon Dannis') {
                setDillonDannisMessages(newMessages);
            }
            setCurMessages(newMessages);

            setTextValue('');
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight + 100;
            }
        }
    };

  return (
    <Stack alignItems="center" overflowY='none' height='100vh' position='relative' pt={mobile ? '2.5vh' : '5vh'}
    sx={{
        backgroundImage: `url(https://i.imgur.com/TDJyi5Q.png)`,
    backgroundSize: 'cover', // Ensures the image covers the entire Box
    backgroundRepeat: 'no-repeat', // Prevents tiling of the imag
    }}
    >
        {messagesOpen && 
            <Stack direction="row" zIndex={1}
                sx={{
                    bgcolor: 'white', 
                    border: '1px solid gray', 
                    borderRadius: '16px', 
                    height: mobile ? '84vh' : '80vh', 
                    width: mobile ? '90vw' : '70vw',
                    overflow: 'hidden'  // Ensures the parent doesn't overflow
                }}
            >
                { mobile ? 
                <Stack width="100%">
                     <Stack direction='row' width="100%" bgcolor="#c5c5c5" alignItems="center">
                        <IconButton onClick={()=>setMessagesOpen(false)}>
                            <CancelRoundedIcon sx={{color: "#c9495e", fontSize: "20px"}}/>
                        </IconButton>
                        <Box flex={1} />
                        {messageClicked ? 
                            <Typography><span style={{color: 'gray',}}>To: </span>{messageClicked}</Typography>
                            :
                            <Typography>Messages</Typography>
                        }
                        <Box flex={1} />
                        {messageClicked ? 
                        <IconButton onClick={()=>setMessageClicked(false)}>
                            <ArrowBackIosNewRoundedIcon sx={{color: "royalBlue", fontSize: "20px"}}/>
                        </IconButton> : <Box width="36px" />}
                    </Stack>
                    <Divider/>
                    {messageClicked ?
                    <>
                    <Box height='1.5vh' />
                    <Stack 
                        sx={{ 
                            overflowY: "auto", 
                            height: '80%'
                        }}
                        ref={chatContainerRef}
                    >
                        {curMessages.map((message, index) => (
                            <ChatBubbleComponent text={message.text} sender={message.sender} avatar={"ksi"} key={index}/>
                        ))}
                    </Stack>
                    <Box px={2} pt={1}>
                        <CustomTextField placeholder='Message' fullWidth={true} value={textValue}
                            onKeyDown={handleSendMessage} 
                            onChange={(e)=> setTextValue(e.target.value)} 
                        />
                    </Box>
                    </>
                    :
                    <Stack>
                        <Box onClick={()=>{setCurMessages(ksiMessages); setMessageClicked('KSI');}} sx={{ cursor: 'pointer'}}>
                            <SideStack name='KSI' message="Sent an audio message" image={ksiImage} main={curMessages === ksiMessages}/>
                        </Box>
                        <Box onClick={()=> {setCurMessages(lawyerMessages); setMessageClicked('Lawyer');}} sx={{ cursor: 'pointer'}}>
                            <SideStack name='Lawyer' message="People are eventually..." image='none' main={curMessages === lawyerMessages}/>
                        </Box>
                        <Box onClick={()=> {setCurMessages(brotherJakeyMessages); setMessageClicked('Brother Jakey');}} sx={{ cursor: 'pointer'}}>
                            <SideStack name='Brother Jakey' message="happy pride" image={jpImage} main={curMessages === brotherJakeyMessages} />
                        </Box>
                        <Box onClick={()=> {setCurMessages(mikeMajlakMessages); setMessageClicked('Mike Majlak');}} sx={{ cursor: 'pointer'}}>
                            <SideStack name='Mike Majlak' message="don't tell me..." image={mmImage} main={curMessages === mikeMajlakMessages}/>
                        </Box>
                        <Box onClick={()=>{setCurMessages(dillonDannisMessages); setMessageClicked('Dillon Dannis');}} sx={{ cursor: 'pointer'}}>
                            <SideStack name='Dillon Dannis' message="Sent an image" image={ddImage} main={curMessages === dillonDannisMessages}/>
                        </Box>
                    </Stack>
                    }
                </Stack> :
                <>
                <Stack 
                    bgcolor="#c5c5c5" 
                    borderRadius='15px 0 0 15px'
                    sx={{ height: '100%' }} // Ensures this Stack takes full height of the parent
                >
                    <Stack direction='row'>
                        <IconButton onClick={()=>setMessagesOpen(false)}>
                            <CancelRoundedIcon sx={{color: "#c9495e", fontSize: "20px"}}/>
                        </IconButton>
                    </Stack>
                    <Divider />
                    <Stack>
                        <Box onClick={()=>{setCurMessages(ksiMessages); setMessageClicked('KSI');}} sx={{ cursor: 'pointer'}}>
                            <SideStack name='KSI' message="Sent an audio message" image={ksiImage} main={curMessages === ksiMessages}/>
                        </Box>
                        <Box onClick={()=> {setCurMessages(lawyerMessages); setMessageClicked('Lawyer');}} sx={{ cursor: 'pointer'}}>
                            <SideStack name='Lawyer' message="People are eventually..." image='none' main={curMessages === lawyerMessages}/>
                        </Box>
                        <Box onClick={()=> {setCurMessages(brotherJakeyMessages); setMessageClicked('Brother Jakey');}} sx={{ cursor: 'pointer'}}>
                            <SideStack name='Brother Jakey' message="happy pride" image={jpImage} main={curMessages === brotherJakeyMessages} />
                        </Box>
                        <Box onClick={()=> {setCurMessages(mikeMajlakMessages); setMessageClicked('Mike Majlak');}} sx={{ cursor: 'pointer'}}>
                            <SideStack name='Mike Majlak' message="don't tell me..." image={mmImage} main={curMessages === mikeMajlakMessages}/>
                        </Box>
                        <Box onClick={()=>{setCurMessages(dillonDannisMessages); setMessageClicked('Dillon Dannis');}} sx={{ cursor: 'pointer'}}>
                            <SideStack name='Dillon Dannis' message="Sent an image" image={ddImage} main={curMessages === dillonDannisMessages}/>
                        </Box>
                    </Stack>
                </Stack>
                <Divider orientation='vertical' flexItem sx={{bgcolor: '#a5a5a5'}}/>
                <Stack 
                    overflow="hidden" // Ensures this Stack doesn't overflow
                    width={mobile ? '67vw' : '47vw'}
                    ref={chatContainerRef}
                >
                    <Box sx={{py: mobile ? 1.03 : 0.74, pl: 2, bgcolor: "#d5d5d5", borderRadius: '0 15px 0 0'}}>
                        <Typography><span style={{color: 'gray',}}>To: </span>{messageClicked}</Typography>
                    </Box>
                    <Divider sx={{bgcolor: '#c5c5c5'}}/>
                    <Box height='1.5vh' />
                    <Stack 
                        sx={{ 
                            overflowY: "auto", 
                            height: '80%' 
                        }}
                        
                    >
                        {curMessages.map((message, index) => (
                            <ChatBubbleComponent text={message.text} sender={message.sender} avatar={"ksi"} key={index}/>
                        ))}
                    </Stack>
                    <Box px={2} pt={1}>
                        <CustomTextField placeholder='Message' fullWidth={true} value={textValue}
                            onKeyDown={handleSendMessage} 
                            onChange={(e)=> setTextValue(e.target.value)} 
                        />
                    </Box>
                </Stack>
                </> }
            </Stack>        
        }

        {musicOpen &&
            <Stack zIndex={1} sx={{bgcolor:'white', border: '1px solid gray', borderRadius: '16px'}} height={mobile ? '84vh' : '80vh'} width={mobile ? '90vw' : '70vw'}>
                <Stack direction="row" alignItems="center" bgcolor="gray"
                    sx={{ bgcolor: '#c5c5c5', borderRadius: '15px 15px 0 0',}}
                >
                    <IconButton onClick={() => setMusicOpen(false)}>
                        <CancelRoundedIcon sx={{ color: '#c9495e', fontSize: '20px' }} />
                    </IconButton>
                    <Box flex={1} />
                    <Typography fontSize={mobile ? 14 : 16}>Music</Typography>
                    <Box flex={1} />
                    <Box width='36px' />
                </Stack>
                <Divider sx={{p: 0}} />
                <Box height='1.5vh' />
                <Music />
            </Stack>
        }
        {photosOpen &&
        <Stack zIndex={1}
            sx={{
            bgcolor: 'white',
            border: '1px solid gray',
            borderRadius: '16px',
            height: mobile ? '84vh' : '80vh',
            width: mobile ? '90vw' : '70vw',
            flexDirection: 'row',
            overflow: 'hidden',
            }}
        >
            <Stack
            sx={{
                bgcolor: '#c5c5c5',
                borderRadius: '15px 0 0 15px',
                width: mobile ? '30%' : '20%',
                height: '100%', // Adjust the height as needed
            }}
            >
            <Stack direction="row">
                <IconButton onClick={() => setPhotosOpen(false)}>
                <CancelRoundedIcon sx={{ color: '#c9495e', fontSize: '20px' }} />
                </IconButton>
            </Stack>
            <Divider />
            <Stack
                spacing={2}
                sx={{
                overflowY: 'auto',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                p: 2,
                }}
            >
                {images.map((image, index) => (
                    <Stack spacing={0.5} alignItems="center" key={index}>
                        <img
                            onClick={() => setPhotoIndex(index)}
                            src={image.img}
                            alt={image.title}
                            style={{
                            width: '80%',
                            height: 'auto',
                            objectFit: 'cover',
                            cursor: 'pointer',
                            }}
                        />
                        <Typography fontSize={mobile ? 11 : 14}>{mobile ? image.title.slice(0, -4) : image.title}</Typography>
                    </Stack>
                ))}
            </Stack>
            </Stack>
            <Divider
            orientation="vertical"
            flexItem
            sx={{ bgcolor: '#a5a5a5' }}
            />
            <Stack
            sx={{
                overflowY: 'auto',
                width: mobile ? '70%' : '80%',
            }}
            >
            <Box
                sx={{
                py: mobile ? 1.12 : 0.74,
                pl: 2,
                bgcolor: '#d5d5d5',
                borderRadius: '0 15px 0 0',
                }}
            >
                <Typography sx={{ color: 'black', fontSize: mobile ? 12 : 16  }}>Photos - {images[photoIndex]?.title}</Typography>
            </Box>
            <Divider sx={{ bgcolor: '#c5c5c5' }} />
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                py: 1,
                height: '100%'
                }}
            >
                {images[photoIndex] && (
                <img
                    src={images[photoIndex].img}
                    alt="carousel"
                    style={{ width: mobile ? '90%' : '70%', height: 'auto' }}
                />
                )}
            </Box>
            </Stack>
        </Stack>
        }
        {browserOpen &&
            <Stack zIndex={1} sx={{bgcolor:'white', border: '1px solid gray', borderRadius: '16px'}} height={mobile ? '84vh' : '80vh' } width={mobile ? '90vw' : '70vw'}>
                <Stack direction="row" alignItems="center" bgcolor="gray"
                sx={{ bgcolor: '#c5c5c5', borderRadius: '15px 15px 0 0',}}
                >
                    <IconButton onClick={() => setBrowserOpen(false)}>
                        <CancelRoundedIcon sx={{ color: '#c9495e', fontSize: '20px' }} />
                    </IconButton>
                    <Box flex={1} />
                    <Typography fontSize={mobile ? 14 : 16}>Internet Browser</Typography>
                    <Box flex={1} />
                    <Box width='36px' />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1} bgcolor='#fcfcfc'>
                    <Box flex={1} />
                    <Typography fontWeight={700} sx={{color: 'black', fontSize: mobile ? 18 : 24, py: 2}} textAlign="center">Search History</Typography>
                    <HistoryRoundedIcon sx={{color: 'black', fontSize: mobile ? 22 : 28}}/>
                    <Box flex={1} />
                </Stack>
                <Divider/>
                <Stack spacing={2.5} pt={2} px={2} pb={3} sx={{overflowY: 'auto'}}>
                    <Stack borderRadius={2} boxShadow={3} bgcolor="#f5f5f5">
                        <Typography sx={{color: 'black', fontSize: mobile ? 14 : 20, p: 1}}>
                            Two Days Ago - June 1, 2024
                        </Typography>
                        <Divider/>
                        <Stack direction="row" aliginItems="center" p={2} spacing="4vw">
                            <Typography sx={{color: 'gray', fontSize: mobile ? 12 : 16}}> 9:15 AM</Typography>
                            <Typography fontSize={mobile ? 13 : 16}>How to tell your wife that you're gay</Typography>
                        </Stack>
                        <Stack direction="row" aliginItems="center" px={2} pb={2} spacing="4vw">
                            <Typography sx={{color: 'gray', fontSize: mobile ? 12 : 16}}> 9:12 AM</Typography>
                            <Typography fontSize={mobile ? 13 : 16}>Is wrestling gay?</Typography>
                        </Stack>
                    </Stack>
                    <Stack borderRadius={2} boxShadow={3} bgcolor="#f5f5f5">
                        <Typography sx={{color: 'black', fontSize: mobile ? 14 : 20, p: 1}}>
                            3 Years Ago - September 3, 2021
                        </Typography>
                        <Divider/>
                        <Stack direction="row" aliginItems="center" p={2} spacing="4vw">
                            <Typography sx={{color: 'gray', fontSize: mobile ? 12 : 16}}> 8:37 AM</Typography>
                            <Typography fontSize={mobile ? 13 : 16}>How to rug your NFT project</Typography>
                        </Stack>
                        <Stack direction="row" aliginItems="center" px={2} pb={2} spacing="4vw">
                            <Typography sx={{color: 'gray', fontSize: mobile ? 12 : 16}}> 7:26 AM</Typography>
                            <Typography fontSize={mobile ? 13 : 16}>Max amount of money you can steal from your fans before SEC gets involved?</Typography>
                        </Stack>
                    </Stack>
                    <Stack borderRadius={2} boxShadow={3} bgcolor="#f5f5f5">
                        <Typography sx={{color: 'black', fontSize: mobile ? 14 : 20, p: 1}}>
                            7 Years Ago - December 31, 2017
                        </Typography>
                        <Divider/>
                        <Stack direction="row" aliginItems="center" p={2} spacing="4vw">
                            <Typography sx={{color: 'gray', fontSize: mobile ? 12 : 16}}> 2:17 PM</Typography>
                            <Typography fontSize={mobile ? 13 : 16}>Can you post a dead body online?</Typography>
                        </Stack>
                        <Stack direction="row" aliginItems="center" px={2} pb={2} spacing="4vw">
                            <Typography sx={{color: 'gray', fontSize: mobile ? 12 : 16}}> 7:32 AM</Typography>
                            <Typography fontSize={mobile ? 13 : 16}>Cool hats</Typography>
                        </Stack>
                        <Stack direction="row" aliginItems="center" px={2} pb={2} spacing="4vw">
                            <Typography sx={{color: 'gray', fontSize: mobile ? 12 : 16}}> 5:49 AM</Typography>
                            <Typography fontSize={mobile ? 13 : 16}>Top 10 most taboo places in Japan</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        }
        {notesOpen &&
            <Stack zIndex={1} sx={{bgcolor:'white', border: '1px solid gray', borderRadius: '16px'}} height={mobile ? '84vh' : '80vh' } width={mobile ? '90vw' : '70vw'}>
                { mobile ? 
                <Stack width="100%">
                     <Stack direction='row' width="100%" bgcolor="#c5c5c5" alignItems="center" borderRadius='14px 14px 0 0'>
                        <IconButton onClick={()=>setNotesOpen(false)}>
                            <CancelRoundedIcon sx={{color: "#c9495e", fontSize: "20px"}}/>
                        </IconButton>
                        <Box flex={1} />
                            <Typography>Notes</Typography>
                        <Box flex={1} />
                        {noteClicked ? 
                        <IconButton onClick={()=>setNoteClicked(false)}>
                            <ArrowBackIosNewRoundedIcon sx={{color: "royalBlue", fontSize: "20px"}}/>
                        </IconButton> : <Box width="36px" />}
                    </Stack>
                    <Divider/>
                    {noteClicked ?
                    <>
                    <Box height='1.5vh' />
                    <Stack 
                        sx={{ 
                            overflowY: "auto", 
                            height: '100%'
                        }}
                    >
                        <Stack spacing={2.5} pt={2} px={2} pb={3} sx={{overflowY: 'auto'}}>
                            <Typography variant="h1" fontWeight="bold">{curNote.title}</Typography>
                            <Typography variant="h3">{curNote.content}</Typography>
                        </Stack>
                    </Stack>
                    </>
                    :
                    <Stack>
                        <Typography sx={{p:1}}>Today</Typography>
                        <Divider/>
                        <Box onClick={()=>{ setCurNote(tokenAddressNote); setNoteClicked('Token Address');}}>
                            <NoteStack title="Token Address" day="Monday" desc="AZ5je..." main={curNote === tokenAddressNote} />

                        </Box>
                        <Box onClick={()=>{ setCurNote(tokenomicsNote); setNoteClicked('Tokenomics');}}>
                            <NoteStack title="Tokenomics" day="Monday" desc="95% Fair..." main={curNote === tokenomicsNote}/>
                        </Box>
                        <Box onClick={()=>{ setCurNote(roadmapNote); setNoteClicked('Roadmap');}}>
                            <NoteStack title="Roadmap" day="Monday" desc="Phase 1..." main={curNote === roadmapNote}/>
                        </Box>
                    </Stack>
                    }
                </Stack> :
                <>
                <Stack direction="row" alignItems="center" bgcolor="gray"
                sx={{ bgcolor: '#c5c5c5', borderRadius: '15px 15px 0 0',}}
                >
                    <IconButton onClick={() => setNotesOpen(false)}>
                        <CancelRoundedIcon sx={{ color: '#c9495e', fontSize: '20px' }} />
                    </IconButton>
                    <Box flex={1} />
                    <Typography fontSize={mobile ? 14 : 16}>Notes</Typography>
                    <Box flex={1} />
                    <Box width='36px' />
                </Stack>
                <Stack direction="row" height="100%">
                    <Stack>
                        <Typography sx={{p:1}}>Today</Typography>
                        <Divider/>
                        <Box onClick={()=>{ setCurNote(tokenAddressNote); setNoteClicked('Token Address');}}>
                            <NoteStack title="Token Address" day="Monday" desc="0x1234..." main={curNote === tokenAddressNote} />

                        </Box>
                        <Box onClick={()=>{ setCurNote(tokenomicsNote); setNoteClicked('Tokenomics');}}>
                            <NoteStack title="Tokenomics" day="Monday" desc="Fair Launch..." main={curNote === tokenomicsNote}/>
                        </Box>
                        <Box onClick={()=>{ setCurNote(roadmapNote); setNoteClicked('Roadmap');}}>
                            <NoteStack title="Roadmap" day="Monday" desc="Phase 1..." main={curNote === roadmapNote}/>
                        </Box>
                    </Stack>
                    <Divider orientation="vertical" flexItem/>
                    <Stack spacing={2.5} pt={2} px={2} pb={3} sx={{overflowY: 'auto'}}>
                        <Typography variant="h1" fontWeight="bold">{curNote.title}</Typography>
                        <Typography variant="h3">{curNote.content}</Typography>
                    </Stack>
                </Stack>
                </> 
                }
            </Stack>
        }
        {/* toolbar */}
        {mobile ? 
        <Stack spacing='10vh' position="absolute" alignItems="center" justifyContent="center">
            <Box flex={1} />
            <Stack direction="row" alignItems='center' spacing='22vw'>
                <Tooltip title="Messages" arrow>
                    <Box onClick={handleMessagesClick}
                    sx={{bgcolor: '#6dc949', width: '70px', height: '70px', borderRadius: 2.5, display:"flex", justifyContent: "center", alignItems:"center", cursor: 'pointer'}}>
                        <ChatBubbleRoundedIcon sx={{color: 'white', fontSize: 48}}/>
                    </Box>
                </Tooltip>
                <Tooltip title="Photos" arrow>
                    <Box onClick={handlePhotosClick} sx={{bgcolor: '#49b0c9', width: '70px', height: '70px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '10px', cursor: 'pointer'}}>
                        <PhotoLibraryRoundedIcon sx={{color: 'white', fontSize: 48}}/>
                    </Box>
                </Tooltip>
            </Stack>
            <Stack direction="row" alignItems='center' spacing='22vw'>
                <Tooltip title="Search Browser" arrow>
                    <Box onClick={handleBrowserClick} 
                    sx={{bgcolor: '#ed5320', width: '70px', height: '70px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '8px', cursor: 'pointer'}}>
                        <TravelExploreRoundedIcon sx={{color: 'white', fontSize: 48}}/>
                    </Box>
                </Tooltip>
                <Tooltip title="Music" arrow>
                    <Box onClick={handleMusicClick} sx={{bgcolor: '#c94949', width: '70px', height: '70px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '10px', cursor: 'pointer'}}>
                        <MusicNoteRoundedIcon sx={{color: 'white', fontSize: 48}}/>
                    </Box>
                </Tooltip>
            </Stack>
            <Stack direction="row" alignItems='center' spacing='22vw'>
                <Tooltip title="Notes" arrow>
                    <Box onClick={handleNotesClick} sx={{bgcolor: '#c9a349', width: '70px', height: '70px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '10px', cursor: 'pointer'}}>
                        <DescriptionRoundedIcon sx={{color: 'white', fontSize: 48}}/>
                    </Box>
                </Tooltip>
                <Tooltip title="Dexscreener" arrow>
                    <Box 
                    onClick={()=>window.open(dexLink, "_blank")}
                    sx={{bgcolor: 'black', width: '70px', height: '70px', borderRadius: 2.5, display:"flex", justifyContent: "center", alignItems:"center", cursor: 'pointer'}}>
                        <img src={dex} alt="dexscreener" style={{width: 'auto', height: '58px'}}/>
                    </Box>
                </Tooltip>
            </Stack>
            <Stack direction="row" alignItems='center' spacing='22vw'>
                <Tooltip title="Telegram" arrow>
                    <Box onClick={()=>window.open("https://t.me/drinkpride", "_blank")}
                    sx={{bgcolor: 'white', width: '70px', height: '70px', borderRadius: 2.5, display:"flex", justifyContent: "center", cursor: 'pointer', alignItems:"center"}}>
                        <Box borderRadius={10} bgcolor='#41a2cc' width='56px' height='56px' display='flex' justifyContent='center' pt='3px'>
                            <img src={telegram} alt="telegram" style={{width: 'auto', height: '48px'}}/>
                        </Box>
                    </Box>
                </Tooltip>
                <Tooltip title="X" arrow>
                    <Box onClick={()=>window.open("https://x.com/pride_on_sol", "_blank")}
                    sx={{bgcolor: 'black', width: '70px', height: '70px', borderRadius: 2.5, display:"flex", justifyContent: "center", cursor: 'pointer', alignItems:"center"}}>
                        <img src={x} alt="x" style={{width: 'auto', height: '48px'}}/>
                    </Box>
                </Tooltip>
            </Stack>
            <Box flex={1} />
        </Stack>
        :<Stack direction="row" spacing={2} py={0.5} px={1.25} alignItems='center'justifyContent='center'
        sx={{position: 'absolute', border: '1px grey solid', borderRadius: 3, height: '70px', bgcolor: '#424242', width: '520px', bottom: '20px'}}>
            <Tooltip title="Messages" arrow>
                <Box onClick={handleMessagesClick}
                sx={{bgcolor: '#6dc949', width: '50px', height: '50px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '10px', cursor: 'pointer'}}>
                    <ChatBubbleRoundedIcon sx={{color: 'white', fontSize: 34}}/>
                </Box>
            </Tooltip>
            <Tooltip title="Photos" arrow>
                <Box onClick={handlePhotosClick} sx={{bgcolor: '#49b0c9', width: '50px', height: '50px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '10px', cursor: 'pointer'}}>
                    <PhotoLibraryRoundedIcon sx={{color: 'white', fontSize: 34}}/>
                </Box>
            </Tooltip>
            <Tooltip title="Search Browser" arrow>
                <Box onClick={handleBrowserClick} 
                sx={{bgcolor: '#ed5320', width: '50px', height: '50px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '8px', cursor: 'pointer'}}>
                    <TravelExploreRoundedIcon sx={{color: 'white', fontSize: 34}}/>
                </Box>
            </Tooltip>
            <Tooltip title="Music" arrow>
                <Box onClick={handleMusicClick} sx={{bgcolor: '#c94949', width: '50px', height: '50px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '10px', cursor: 'pointer'}}>
                    <MusicNoteRoundedIcon sx={{color: 'white', fontSize: 34}}/>
                </Box>
            </Tooltip>
            <Tooltip title="Notes" arrow>
                <Box onClick={handleNotesClick} sx={{bgcolor: '#c9a349', width: '50px', height: '50px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '10px', cursor: 'pointer'}}>
                    <DescriptionRoundedIcon sx={{color: 'white', fontSize: 34}}/>
                </Box>
            </Tooltip>
            <Tooltip title="Dexscreener" arrow>
                <Box onClick={()=>window.open(dexLink, "_blank")}
                sx={{bgcolor: 'black', width: '50px', height: '50px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '6px', cursor: 'pointer'}}>
                    <img src={dex} alt="dexscreener" style={{width: 'auto', height: '40px'}}/>
                </Box>
            </Tooltip>
            <Tooltip title="Telegram" arrow>
                <Box onClick={()=>window.open("https://t.me/drinkpride", "_blank")}
                sx={{bgcolor: 'white', width: '50px', height: '50px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '4px', cursor: 'pointer'}}>
                    <Box borderRadius={10} bgcolor='#41a2cc' width='42px' height='42px' display='flex' justifyContent='center' pt='4px'>
                        <img src={telegram} alt="telegram" style={{width: 'auto', height: '32px'}}/>
                    </Box>
                </Box>
            </Tooltip>
            <Tooltip title="X" arrow>
                <Box onClick={()=>window.open("https://x.com/pride_on_sol", "_blank")}
                sx={{bgcolor: 'black', width: '50px', height: '50px', borderRadius: 2.5, display:"flex", justifyContent: "center", pt: '8px', cursor: 'pointer'}}>
                    <img src={x} alt="x" style={{width: 'auto', height: '34px'}}/>
                </Box>
            </Tooltip>
        </Stack> 
        }
    </Stack>
  );
}

export default Home;
