import React from 'react';
import { Box, Stack, Typography, Divider, useMediaQuery } from '@mui/material';

function NoteStack({title, day, desc, main=false}) {
    const mobile = useMediaQuery('(max-width:915px)');
  return (
    <Box width={mobile ? '100%' : "18vw"} p={1} >
        <Stack direction="row" p={1} pr={0} py={0} spacing={1} bgcolor={main ? "#c9a84c" : "#fff"}
        sx={{cursor: "pointer", ":hover": {bgcolor: "#c4bfb0"}, borderRadius: 2 }} >
            <Stack direction="column" alignItems="start" spacing={0} width="100%" py={1} px={2}>
                <Typography color={main ? "#fff" : '#696969'} fontSize="2.5vh" fontWeight="bold">
                    {title}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography color={main ? "#fff" : '#696969'} fontSize="2vh">
                        {day}
                    </Typography>
                    <Typography color={main ? "#f5f5f5" : '#696969'} fontSize="2vh">
                        {desc}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
        <Divider sx={{ml: 3, pt: 1, pb:0}}/>
    </Box>
  );
}

export default NoteStack;
