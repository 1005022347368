import React from 'react';
import { Box, IconButton, Slider, Typography, Stack, useMediaQuery } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useState, useRef } from 'react';

const AudioPlayer = ({ src, title }) => {
    const mobile = useMediaQuery('(max-width:915px)');
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleSliderChange = (event, newValue) => {
    audioRef.current.currentTime = newValue;
    setCurrentTime(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        bgcolor: '#e1f5fe',
        borderRadius: '16px',
        padding: '8px 16px',
        maxWidth: '500px',
        width: mobile ? '45vw' : '20vw'
      }}
    >
      <IconButton onClick={togglePlayPause}>
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <Slider
      size="small"
        value={currentTime}
        min={0}
        max={audioRef.current?.duration || 0}
        onChange={handleSliderChange}
        sx={{ flexGrow: 1, ml: 0.5, mr: 2 }}
      />
      <Stack alignItems="end">
        <Typography fontSize={mobile ? 12 : '1.1vw'}>{title}</Typography>
      </Stack>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => setIsPlaying(false)}
      />
    </Box>
  );
};

export default AudioPlayer;
